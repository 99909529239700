import axios, { Response } from 'axios'
import { baseURL } from '@/config/index'

const instance = axios.create({
    baseURL,
    timeout: 3000,
});

instance.interceptors.response.use<Response>(function (response) {
    return response.data
})

export default instance